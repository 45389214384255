<template>
  <v-dialog v-model="dialog" max-width="500px" :persistent="loading" @click:outside="close">
    <v-card>
      <v-card-title>
        <span class="text-h5">{{ !payment.id ? 'Criar pagamento' : 'Editar pagamento' }}</span>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" md="5">
              <v-text-field-money
                v-model="payment.value"
                label="Valor"
                :properties="{
                  prefix: 'R$',
                  outlined: true,
                  dense: true,
                  placeholder: ' ',
                  hideDetails: true,
                  rules: rulesValue,
                }"
                v-bind:options="{
                  locale: 'pt-BR',
                  length: 11,
                  precision: 2,
                  empty: '0',
                }"
              />
            </v-col>

            <v-col cols="12" md="7">
              <v-dialog ref="dialog" v-model="modal" persistent width="290px">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    @focus.once="modal = true"
                    :value="validityFormatted"
                    label="Data de Vencimento"
                    readonly
                    prepend-icon="mdi-calendar"
                    outlined
                    dense
                    v-bind="attrs"
                    v-on="on"
                    hide-details
                    :rules="[v => !!v || 'Data de vencimento é obrigatória']"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="payment.validity" locale="pt-br" scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="modal = false">Cancelar</v-btn>
                </v-date-picker>
              </v-dialog>
            </v-col>

            <v-col cols="12" class="pt-0">
              <v-textarea v-model="payment.description" label="Descrição" outlined dense hide-details></v-textarea>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions class="px-8 pb-8">
        <v-spacer></v-spacer>
        <v-btn color="primary" class="mr-2 mt-2" outlined @click="close" :disabled="loading">Cancelar</v-btn>
        <v-btn color="primary" class="mt-2" @click="save" :loading="loading">Salvar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import RENTAL from '@/services/rental'
import moment from 'moment'

const defaultData = () => ({
  validity: null,
  description: '',
})
export default {
  data() {
    return {
      dialog: false,
      modal: false,
      loading: false,
      valid: false,
      rental: {},
      payment: defaultData(),
      rulesValue: [
        v => !!v || 'Campo obrigatório',
        v => parseFloat(v.replace('.', '').replace(',', '.')) > 1 || 'Valor deve ser maior que 1',
      ],
    }
  },
  methods: {
    moment,
    async save() {
      try {
        this.loading = true
        var data = {
          value: this.payment.value,
          validity: moment(this.payment.validity)
            .endOf('day')
            .toISOString(),
          description: this.payment.description,
        }

        var response
        if (!this.payment.id) response = await RENTAL.payment.create(this.rental.id, data)
        else response = response = await RENTAL.payment.update(this.rental.id, { id: this.payment.id, ...data })

        this.$emit('success', response.payment)
        this.loading = false
        this.close()
      } catch (error) {
        console.log(error)
        this.$toast.fire({
          icon: 'error',
          title: error.message || 'Erro ao salvar pagamento',
        })
        this.$emit('error', error)
      } finally {
        this.loading = false
      }
    },
    selectValidity(date) {
      this.payment.validity = date
    },
    open({ payment, rental }) {
      this.loading = false
      this.rental = rental
      this.payment = Object.assign(defaultData(), payment)
      this.dialog = true
    },
    close() {
      if (this.loading) return
      this.loading = false
      this.dialog = false
    },
  },
  watch: {
    'payment.validity': function(val) {
      this.modal = false
    },
  },
  filters: {
    date(value) {
      if (!value) return ''
      return new Date(value).toLocaleDateString('pt-BR')
    },
  },
  computed: {
    validityFormatted() {
      return this.payment.validity ? this.moment(this.payment.validity).format('DD/MM/YYYY') : ''
    },
  },

  created() {
    this.$parent.$on(this.componentName, this.open)
  },
  props: {
    componentName: {
      type: String,
      default: 'payment-modal',
    },
  },
}
</script>

<style></style>

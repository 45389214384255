<template>
  <v-dialog v-model="dialog" max-width="800px">
    <v-card>
      <v-card-title class="justify-space-between" v-if="!!contract">
        <span class="text-h5">Contrato {{ contract.id.slice(0, 8) }}</span>
        <v-btn icon color="secondary" v-if="['pending', 'active'].includes(contract.status)" @click="cancel">
          <v-icon>mdi-delete-outline</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text v-if="!!contract">
        <v-card outlined class="pa-4 mb-3">
          <div class="d-flex justify-space-between align-center mb-2">
            <h4>Histórico de Alterações</h4>
            <v-btn small @click="edit" text>
              <v-icon small left>mdi-pencil</v-icon>
              Editar
            </v-btn>
          </div>
          <contract-versions :contract="contract" />
        </v-card>
        <div class="d-flex flex-column gap-2">
          <v-btn
            color="success"
            block
            :disabled="loading"
            @click="upload"
            v-if="!contract.url && ['pending', 'active'].includes(contract.status)"
          >
            <v-icon left>mdi-tray-arrow-up</v-icon> Enviar contrato
          </v-btn>
          <v-btn :disabled="loading" @click="download" v-else-if="!!contract.url">
            <v-icon>mdi-tray-arrow-down</v-icon>
            Baixar contrato
          </v-btn>
          <v-btn
            color="success"
            block
            :disabled="loading"
            @click="sign"
            v-if="['pending', 'active'].includes(contract.status)"
          >
            <v-icon left>mdi-file-sign</v-icon>
            <template v-if="!contract.DocSign || contract.DocSign.status == 'canceled'">
              Enviar para assinatura
            </template>
            <template v-else-if="contract.DocSign.status == 'pending'"> Acompanhar assinaturas </template>
            <template v-else-if="contract.DocSign.status == 'signed'"> Ver assinaturas </template>
          </v-btn>
        </div>
      </v-card-text>

      <v-card-actions class="justify-center">
        <v-btn color="primary" class="mr-2 mt-2" outlined :disabled="loading" @click="close">Fechar</v-btn>
      </v-card-actions>
    </v-card>
    <contract-upload @success="refresh" />
    <contract-cancel @success="refresh" />
    <contract-sign @success="refresh" />
    <contract-update @success="refresh" />
  </v-dialog>
</template>

<script>
import moment from 'moment'
import CONTRACT from '@/services/contract'
import ContractCancel from '@/components/modals/ContractCancel.vue'
import ContractUpload from '@/components/modals/ContractUpload.vue'
import ContractUpdate from '@/components/modals/ContractUpdateModal.vue'
import ContractVersions from './contract/ContractVersions.vue'
import ContractSign from './ContractSign.vue'
export default {
  components: {
    ContractCancel,
    ContractUpload,
    ContractVersions,
    ContractUpdate,
    ContractSign,
  },
  data() {
    return {
      loading: false,
      dialog: false,
      contract: null,
      rental: null,
    }
  },
  methods: {
    refresh() {
      this.getContractDetails()
      this.$emit('refresh')
    },
    sign() {
      this.$root.$emit('contract-sign', { contract: this.contract, rental: this.rental })
    },
    upload() {
      this.$root.$emit('contract-upload', { contract: this.contract })
    },
    download() {
      window.open(this.contract.url, '_blank')
    },
    cancel() {
      this.$emit('contract-cancel', { rental: this.rental, contract: this.contract })
    },
    edit() {
      this.$root.$emit('contract-update-modal', { rental: this.rental, contract: this.contract })
    },
    async getContractDetails() {
      this.loading = true
      try {
        const data = await CONTRACT.getOne(this.contract.id)
        this.contract = data.contract
      } catch (error) {
        this.$toast.fire({
          icon: 'error',
          title: error.message || 'Erro ao carregar detalhes do contrato',
        })
      }
      this.loading = false
    },
    open({ rental, contract }) {
      this.rental = rental
      this.contract = contract
      this.getContractDetails()
      this.dialog = true
    },
    close() {
      this.loading = false
      this.dialog = false
      this.rental = null
      this.contract = null
    },
  },
  filters: {
    formatDate(value) {
      if (!value) return ''
      return moment(value).format('DD/MM/YYYY')
    },
    formatMonth(value) {
      if (!value) return ''
      return moment(value).format('MM/YYYY')
    },
  },
  created() {
    this.$parent.$on(this.componentName, this.open)
  },
  props: {
    componentName: {
      type: String,
      default: 'contract-detail',
    },
  },
}
</script>

<style></style>

<template>
  <div class="d-flex flex-column">
    <v-icon large :color="currentStep.color"> {{ currentStep.icon }} </v-icon>
    <h3>{{ currentStep.title }}</h3>

    <v-select
      v-if="currentStep.selectDocumentModel"
      v-model="selectedDocumentModel"
      :items="documentModelsList"
      label="Modelo de contrato"
      outlined
      hide-details
      class="mt-2"
      dense
    />
    <v-btn
      v-if="!currentStep.hiddenBtn"
      :disabled="currentStep.disabled || !selectedDocumentModel"
      :loading="currentStep.loading"
      elevation="0"
      @click="start"
      :color="currentStep.color"
      outlined
      class="mt-4"
    >
      {{ currentStep.label }}
    </v-btn>
    <div>
      <v-slide-y-transition leave-absolute hide-on-leave>
        <span v-if="step == 'fetching-data'" :class="`${currentStep.color}--text`">{{ currentStep.description }}</span>
      </v-slide-y-transition>
      <v-slide-y-transition leave-absolute hide-on-leave>
        <span v-if="step == 'generating'" :class="`${currentStep.color}--text`">{{ currentStep.description }}</span>
      </v-slide-y-transition>
      <v-slide-y-transition leave-absolute hide-on-leave>
        <span v-if="step == 'downloaded'" :class="`${currentStep.color}--text`">{{ currentStep.description }}</span>
      </v-slide-y-transition>
      <v-slide-y-transition leave-absolute hide-on-leave>
        <span v-if="step == 'error'" :class="`${currentStep.color}--text`">{{ currentStep.description }}</span>
      </v-slide-y-transition>
      <v-slide-y-transition leave-absolute hide-on-leave>
        <span v-if="step == 'document-models-not-found'" :class="`${currentStep.color}--text`">{{
          currentStep.description
        }}</span>
      </v-slide-y-transition>
    </div>
  </div>
</template>

<script>
import RENTAL from '@/services/rental'
import DOCUMENT_MODEL from '@/services/documentModel'
export default {
  data: () => ({
    documentModels: [],
    selectedDocumentModel: null,
    step: 'not-started',
    steps: {
      'not-started': {
        title: '',
        description: '',
        icon: 'mdi-file-document-outline',
        color: 'grey',
        label: 'Gerar contrato',
        loading: false,
        selectDocumentModel: true,
      },
      'fetching-data': {
        title: 'Buscando dados',
        description: 'Buscando dados do contrato',
        icon: 'mdi-file-document-outline',
        color: 'grey',
        label: 'Gerar contrato',
        loading: true,
      },
      generating: {
        title: 'Gerando contrato',
        description: 'Gerando contrato',
        icon: 'mdi-file-document-outline',
        color: 'grey',
        loading: true,
      },
      downloaded: {
        title: 'Baixado',
        description: 'O contrato foi baixado',
        icon: 'mdi-file-document-outline',
        color: 'success',
        label: 'Gerar novamente',
        loading: false,
        selectDocumentModel: true,
      },
      error: {
        title: 'Ops...',
        description: '',
        icon: 'mdi-file-document-outline',
        color: 'error',
        label: 'Gerar novamente',
        loading: false,
        selectDocumentModel: true,
      },
      'document-models-not-found': {
        title: 'Nenhum modelo de contrato cadastrado',
        description: 'Cadastre um modelo de contrato para gerar um contrato',
        icon: 'mdi-file-document-outline',
        color: 'error',
        label: 'Gerar novamente',
        loading: false,
        hiddenBtn: true,
      },
    },
  }),
  methods: {
    async start() {
      try {
        this.step = 'fetching-data'
        const data = await this.fetchData()
        this.downloadFile(data)
        this.step = 'downloaded'
      } catch (e) {
        this.steps.error.description = e.message || 'Erro ao gerar contrato'
        this.step = 'error'
      }
    },
    async fetchData() {
      return await RENTAL.contract.template(this.contract.Rental.id, this.contract.id, this.selectedDocumentModel)
    },
    async downloadFile(blob) {
      var link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = `contrato-${this.contract.id}.docx`
      link.click()
    },
    async getModels() {
      const { documentModels } = await DOCUMENT_MODEL.getAll('contract')
      const dms = documentModels.filter(dm => dm.active)

      if (dms.length === 0) this.step = 'document-models-not-found'
      else {
        this.documentModels = dms
        const defaultModel = dms.find(model => model.default)
        this.selectedDocumentModel = defaultModel.id
      }
    },
  },
  computed: {
    currentStep() {
      return this.steps[this.step]
    },
    documentModelsList() {
      return this.documentModels
        .filter(dm => dm.active)
        .map(model => ({
          text: model.name,
          value: model.id,
        }))
    },
  },
  mounted() {
    this.getModels()
  },
  props: {
    contract: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style></style>

<template>
  <v-dialog v-model="dialog" max-width="500px" :persistent="loading" @click:outside="close">
    <v-card class="pa-4" v-if="!!payment && !!rental">
      <v-card-title class="justify-center text-center">Tem certeza que deseja <br />cancelar este boleto?</v-card-title>
      <v-card-actions class="mt-4">
        <v-spacer></v-spacer>
        <v-btn color="primary" class="mr-2 mt-2" outlined @click="close" :disabled="loading">Cancelar</v-btn>
        <v-btn color="primary" class="mt-2" @click="destroy" :loading="loading">Salvar</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import RENTAL from '@/services/rental'

export default {
  data() {
    return {
      dialog: false,
      loading: false,
      payment: null,
      rental: null,
    }
  },
  methods: {
    async destroy() {
      try {
        this.loading = true
        await RENTAL.payment.cancel(this.rental.id, this.payment.id)
        this.$emit('success')
        this.loading = false
        this.close()
      } catch (error) {
        this.loading = false
        this.$toast.fire({
          icon: 'error',
          title: error.message || 'Erro ao excluir usuário',
        })
      }
    },
    close() {
      if (this.loading) return
      this.dialog = false
      this.loading = false
      this.payment = null
      this.rental = null
    },
    open({ payment, rental }) {
      this.dialog = true
      this.payment = payment
      this.rental = rental
    },
  },
  mounted() {
    this.$parent.$on('payment-delete', this.open)
  },
}
</script>

<style></style>

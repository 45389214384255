<template>
  <v-dialog v-model="dialog" max-width="500px" @click:outside="close" :persistent="loading">
    <v-card>
      <v-card-title>
        <span class="text-h5">Enviar Contrato</span>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-card outlined>
            <v-card-text class="text-center">
              <generate-contract :contract="contract" />
            </v-card-text>
          </v-card>
          <div class="text-center my-4">
            <v-divider style="margin-bottom: -10px" />
            <span class="text--white font-weight-bold">OU</span>
          </div>
          <v-form v-model="valid">
            <v-row>
              <v-col cols="12">
                <v-file-input
                  v-model="attachment.file"
                  label="Enviar contrato"
                  outlined
                  dense
                  :rules="rules.file"
                ></v-file-input>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>

      <v-card-actions class="px-8 pb-8">
        <v-spacer></v-spacer>
        <v-btn color="primary" class="mr-2 mt-2" outlined @click="close" :disabled="loading">Cancelar</v-btn>
        <v-btn color="primary" class="mt-2" @click="save" :loading="loading" :disabled="!valid">Salvar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import RENTAL from '@/services/rental'
import GenerateContract from '../contract/GenerateContract.vue'

const defaultData = () => ({
  file: null,
})

export default {
  components: { GenerateContract },
  data: () => ({
    dialog: false,
    loading: false,
    contract: null,
    valid: true,
    attachment: defaultData(),
    rules: {
      file: [
        v => !!v || 'O anexo é obrigatório',
        v => (!!v && v.size <= 10485760) || 'O anexo deve ter no máximo 10MB',
      ],
    },
  }),
  methods: {
    async save() {
      try {
        this.loading = true
        if (!this.attachment.file) throw new Error('O anexo é obrigatório')
        var response = await RENTAL.contract.upload(this.contract.Rental.id, this.contract.id, this.attachment)
        this.$emit('success', response.attachment)
        this.loading = false
        this.close()
      } catch (error) {
        console.log(error)
        this.loading = false
        this.$toast.fire({
          icon: 'error',
          title: error.message || 'Erro ao salvar pagamento',
        })
        this.$emit('error', error)
      }
    },
    open({ contract }) {
      this.attachment = defaultData()
      this.contract = contract
      this.dialog = true
    },
    close() {
      if (this.loading) return
      this.dialog = false
      this.loading = false
      this.contract = null
    },
  },
  mounted() {
    this.$root.$on('contract-upload', this.open)
  },
}
</script>

<style></style>

<template>
  <v-dialog v-model="dialog" max-width="500px" @click:outside="close" :persistent="loading">
    <v-card>
      <v-card-title>
        <span class="text-h5">Solicitar Vistoria</span>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-form v-model="valid">
            <v-row>
              <v-col cols="12">
                <v-select
                  v-model="inspection.immobile"
                  :items="immobiles"
                  item-value="id"
                  label="Imóvel"
                  outlined
                  :rules="[v => !!v || 'Selecione um imóvel']"
                  dense
                  item-disabled="hasPendingInspection"
                  hide-details
                >
                  <template v-slot:selection="{ item }">
                    <span v-if="item" class="ma-0">
                      {{ item.Address | formatAddress }}
                    </span>
                  </template>
                  <template v-slot:item="{ item }">
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ item.Address | formatAddress }}
                        <v-chip v-if="item.hasPendingInspection" small color="error" class="ml-1">
                          Vistoria Pendente
                        </v-chip>
                        <v-chip v-else-if="item.lastInspect" small color="secondary" class="ml-1">
                          Última vistoria em {{ item.lastInspect.uploadedAt | date }}
                        </v-chip>
                      </v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-select>
              </v-col>

              <v-col cols="12">
                <v-select
                  v-model="inspection.responsible"
                  :items="users"
                  :rules="[v => !!v || 'Selecione um responsável pela vistoria']"
                  item-text="name"
                  item-value="id"
                  label="Responsável pela vistoria"
                  outlined
                  dense
                  hide-details
                ></v-select>
              </v-col>
              <v-col cols="12">
                <v-textarea
                  v-model="inspection.observation"
                  label="Observação"
                  placeholder="Qualquer informação ou solicitar que deve ser informado ao responsável pela vistoria"
                  outlined
                  dense
                  hide-details
                ></v-textarea>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>

      <v-card-actions class="px-8 pb-8">
        <v-spacer></v-spacer>
        <v-btn color="primary" class="mr-2 mt-2" outlined @click="close" :disabled="loading">Cancelar</v-btn>
        <v-btn color="primary" class="mt-2" @click="save" :loading="loading" :disabled="!valid">Salvar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import USER from '@/services/user'
import RENTAL from '@/services/rental'

const defaultData = () => ({
  observation: '',
  responsible: null,
  immobile: null,
})

export default {
  data: () => ({
    valid: true,
    dialog: false,
    loading: false,
    users: [],
    inspection: defaultData(),
    rules: {},
  }),
  computed: {
    immobiles() {
      const {
        rental,
        rental: { Inspection },
      } = this
      return rental.Immobiles.map(immobile => {
        return {
          id: immobile.id,
          Address: immobile.Address,
          hasPendingInspection: Inspection.some(inspection => inspection.immobileId === immobile.id && !inspection.url),
          lastInspect: Inspection.filter(inspection => inspection.immobileId === immobile.id).sort((a, b) => {
            return new Date(b.createdAt) - new Date(a.createdAt)
          })[0],
        }
      })
    },
  },
  methods: {
    async getUsers() {
      try {
        const { users } = await USER.getAll()
        this.users = users.filter(user => (user.permission & 536870912) === 536870912)
      } catch (e) {
        this.$toast.fire({
          icon: 'error',
          title: e.message || 'Erro ao buscar usuários',
        })
      }
    },
    async save() {
      try {
        this.loading = true
        var { inspection } = await RENTAL.inspection.create(this.rental.id, this.inspection)
        this.$emit('success', inspection)
        this.loading = false
        this.close()
      } catch (error) {
        console.log(error)
        this.loading = false
        this.$toast.fire({
          icon: 'error',
          title: error.message || 'Erro ao criar vistoria',
        })
        this.$emit('error', error)
      }
    },
    open() {
      this.inspection = defaultData()
      this.dialog = true
    },
    close() {
      if (this.loading) return
      this.dialog = false
      this.loading = false
    },
  },
  props: {
    rental: {
      type: Object,
      default: () => {},
    },
  },

  mounted() {
    this.$parent.$on('add-inspection', this.open)
    this.getUsers()
  },
}
</script>

<style></style>

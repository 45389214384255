<template>
  <div>
    <v-card flat class="mt-1" :loading="loading">
      <v-card-title>
        <v-text-field
          class="pt-0"
          v-model="search"
          append-icon="mdi-magnify"
          label="Pesquisar..."
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="rental.Attachments"
          :search="search"
          :header-props="{ sortByText: 'Ordenar por:' }"
          :sort-desc="true"
          sort-by="date"
          hide-default-footer
        >
          <template v-slot:top>
            <v-btn @click="newAttachment" color="primary" dark class="mb-4" :disabled="loading">
              Adicionar Anexo
            </v-btn>
          </template>
          <template v-slot:[`item.size`]="{ item }">
            {{ item.size | fileSize }}
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon class="mr-4" @click="goToURL(item)" :disabled="loading">mdi-tray-arrow-down</v-icon>
            <v-icon @click="deleteAttachment(item)" :disabled="loading">mdi-delete-outline</v-icon>
          </template>
          <template v-slot:no-results>
            <v-btn color="primary" @click="search = ''">Resetar</v-btn>
          </template>
        </v-data-table>
        <v-card outlined v-if="immobileAttachments">
          <h4 class="ma-4 mb-2">Anexos de Imóveis</h4>
          <v-data-table
            :headers="immobileHeaders"
            :items="immobileAttachments"
            :search="search"
            :header-props="{ sortByText: 'Ordenar por:' }"
            :sort-desc="true"
            sort-by="date"
            hide-default-footer
          >
            <template v-slot:[`item.size`]="{ item }">
              {{ item.size | fileSize }}
            </template>
            <template v-slot:[`item.immobile.id`]="{ item }">
              <v-chip small label>
                {{ item.immobile.Address | formatAddress }}
              </v-chip>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-icon class="mr-4" @click="goToURL(item)" :disabled="loading">mdi-tray-arrow-down</v-icon>
            </template>
            <template v-slot:no-results>
              <v-btn color="primary" @click="search = ''">Resetar</v-btn>
            </template>
          </v-data-table>
        </v-card>
      </v-card-text>
    </v-card>
    <attachment-modal @success="$emit('refresh')"></attachment-modal>
    <attachment-delete @success="$emit('refresh')"></attachment-delete>
  </div>
</template>

<script>
import AttachmentModal from '@/components/modals/AttachmentModal.vue'
import AttachmentDelete from '@/components/modals/AttachmentDelete.vue'
export default {
  components: { AttachmentModal, AttachmentDelete },
  data: () => ({
    search: '',
    headers: [
      { text: 'Nome', value: 'name' },
      { text: 'Tamanho', value: 'size' },
      { text: 'Ações', value: 'actions', sortable: false, align: 'end' },
    ],
    immobileHeaders: [
      { text: 'Nome', value: 'name' },
      { text: 'Tamanho', value: 'size' },
      { text: 'Imóvel', value: 'immobile.id' },
      { text: 'Ações', value: 'actions', sortable: false, align: 'end' },
    ],
  }),
  methods: {
    newAttachment() {
      this.$emit('add-attachment', this.rental)
    },
    deleteAttachment(attachment) {
      this.$emit('delete-attachment', { rental: this.rental, attachment })
    },
    goToURL(item) {
      window.open(item.url, '_blank')
    },
  },
  computed: {
    immobileAttachments() {
      return this.rental.Immobiles.reduce((acc, immobile) => {
        const attachments = immobile.Attachments.map(attachment => {
          return {
            ...attachment,
            immobile: immobile,
          }
        })
        return [...acc, ...attachments]
      }, [])
    },
  },
  filters: {
    fileSize(bytes) {
      let si = false,
        dp = 1
      const thresh = si ? 1000 : 1024

      if (Math.abs(bytes) < thresh) {
        return bytes + ' B'
      }

      const units = si
        ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
        : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']
      let u = -1
      const r = 10 ** dp

      do {
        bytes /= thresh
        ++u
      } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1)

      return bytes.toFixed(dp) + ' ' + units[u]
    },
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    rental: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.v-card__title {
  text-align: center !important;
  display: inherit;
}
</style>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"max-width":"500px","persistent":_vm.loading},on:{"click:outside":_vm.close},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v(_vm._s(!_vm.payment.id ? 'Criar pagamento' : 'Editar pagamento'))])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"5"}},[_c('v-text-field-money',{attrs:{"label":"Valor","properties":{
                prefix: 'R$',
                outlined: true,
                dense: true,
                placeholder: ' ',
                hideDetails: true,
                rules: _vm.rulesValue,
              },"options":{
                locale: 'pt-BR',
                length: 11,
                precision: 2,
                empty: '0',
              }},model:{value:(_vm.payment.value),callback:function ($$v) {_vm.$set(_vm.payment, "value", $$v)},expression:"payment.value"}})],1),_c('v-col',{attrs:{"cols":"12","md":"7"}},[_c('v-dialog',{ref:"dialog",attrs:{"persistent":"","width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-text-field',_vm._g(_vm._b({attrs:{"value":_vm.validityFormatted,"label":"Data de Vencimento","readonly":"","prepend-icon":"mdi-calendar","outlined":"","dense":"","hide-details":"","rules":[v => !!v || 'Data de vencimento é obrigatória']},on:{"~focus":function($event){_vm.modal = true}}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.modal),callback:function ($$v) {_vm.modal=$$v},expression:"modal"}},[_c('v-date-picker',{attrs:{"locale":"pt-br","scrollable":""},model:{value:(_vm.payment.validity),callback:function ($$v) {_vm.$set(_vm.payment, "validity", $$v)},expression:"payment.validity"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.modal = false}}},[_vm._v("Cancelar")])],1)],1)],1),_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12"}},[_c('v-textarea',{attrs:{"label":"Descrição","outlined":"","dense":"","hide-details":""},model:{value:(_vm.payment.description),callback:function ($$v) {_vm.$set(_vm.payment, "description", $$v)},expression:"payment.description"}})],1)],1)],1)],1),_c('v-card-actions',{staticClass:"px-8 pb-8"},[_c('v-spacer'),_c('v-btn',{staticClass:"mr-2 mt-2",attrs:{"color":"primary","outlined":"","disabled":_vm.loading},on:{"click":_vm.close}},[_vm._v("Cancelar")]),_c('v-btn',{staticClass:"mt-2",attrs:{"color":"primary","loading":_vm.loading},on:{"click":_vm.save}},[_vm._v("Salvar")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
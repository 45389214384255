<template>
  <div>
    <v-card :loading="loading" flat>
      <div class="pa-3">
        <v-card-text>
          <v-card-title class="px-0 pt-0">
            <v-text-field
              class="pt-0"
              v-model="search"
              append-icon="mdi-magnify"
              label="Pesquisar..."
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="rental.Contracts"
            :search="search"
            :header-props="{ sortByText: 'Ordenar por:' }"
            :sort-desc="true"
            sort-by="date"
            hide-default-footer
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-btn
                  color="primary"
                  dark
                  class="mb-4"
                  style="margin-left: -17px"
                  @click="addContract"
                  :disabled="hasContractActive || loading"
                >
                  Gerar Contrato
                </v-btn>
                <v-alert v-if="hasContractActive" border="left" dense text type="info" class="py-2 ml-2">
                  Já existe um contrato ativo para esta locação
                </v-alert>
              </v-toolbar>
            </template>
            <template v-slot:[`item.id`]="{ item }">
              {{ item.id | idFilter }}
            </template>
            <template v-slot:[`item.startDate`]="{ item }">
              {{ item.startDate | date }} | {{ item.endDate | date }}
            </template>
            <template v-slot:[`item.endDate`]="{ item }"> {{ item | duration }} meses </template>
            <template v-slot:[`item.value`]="{ item }">
              {{ item.value | money }}
            </template>
            <template v-slot:[`item.status`]="{ item }">
              <v-chip :color="statusChip[item.status].color" small outlined label>
                {{ statusChip[item.status].text }}
              </v-chip>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-btn icon :disabled="loading" @click="view(item)">
                <v-icon>mdi-eye</v-icon>
              </v-btn>
            </template>
            <template v-slot:no-results>
              <v-btn color="primary" @click="search = ''">Limpar filtro</v-btn>
            </template>
          </v-data-table>
        </v-card-text>
      </div>
    </v-card>

    <contract-modal @success="$emit('refresh')" />
    <contract-details-modal @success="$emit('refresh')" />
  </div>
</template>

<script>
import moment from 'moment'
import ContractModal from '@/components/modals/ContractModal.vue'

import ContractDetailsModal from '@/components/modals/ContractDetailsModal.vue'
export default {
  components: { ContractModal, ContractDetailsModal },
  data: () => ({
    search: '',
    headers: [
      { text: 'Nº Contrato', value: 'id', sortable: true, align: 'start' },
      { text: 'Início / Fim', value: 'startDate', sortable: true, align: 'center' },
      { text: 'Duração', value: 'endDate', sortable: true, align: 'center' },
      { text: 'Valor', value: 'value', sortable: true, align: 'center' },
      { text: 'Dia do Pagamento', value: 'payday', sortable: true, align: 'center' },
      { text: 'Status', value: 'status', sortable: true, align: 'center' },
      { text: 'Ações', value: 'actions', sortable: false, align: 'end' },
    ],
    statusChip: {
      active: { text: 'Ativo', color: 'success' },
      pending: { text: 'Pendente', color: 'warning' },
      inactive: { text: 'Cancelado', color: 'error' },
      expired: { text: 'Vencido', color: 'accent' },
    },
  }),

  methods: {
    view(item) {
      this.$emit('contract-detail', { rental: this.rental, contract: item })
    },
    addContract() {
      this.$emit('contract-modal', { rental: this.rental })
    },
    
  },
  computed: {
    hasContractActive() {
      return this.rental.Contracts.some(contract => contract.status === 'active' || contract.status === 'pending')
    },
  },
  filters: {
    duration(item) {
      return moment(item.endDate).diff(moment(item.startDate), 'months')
    },
    date(value) {
      return moment(value).format('DD/MM/YY')
    },
    money(value) {
      value = parseFloat(value)
      return value.toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      })
    },
  },

  props: {
    rental: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      default: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.v-card__title {
  text-align: center !important;
  display: inherit;
}
</style>

<template>
  <v-dialog v-model="dialog" max-width="700px" scrollable :persistent="loading">
    <v-card>
      <v-card-text class="pa-0">
        <v-stepper v-model="step">
          <v-stepper-header>
            <v-stepper-step :complete="!!contract?.docSignId" step="1"> Enviar para assinatura </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step :complete="false" step="2"> Acompanhar assinatura </v-stepper-step>
          </v-stepper-header>

          <v-stepper-items>
            <v-stepper-content step="1">
              <v-card class="pa-4" outlined>
                <h3 class="mb-2">Requisitos</h3>
                <div class="d-flex flex-column gap-2">
                  <div class="d-flex justify-space-between align-center">
                    <h5>Contrato</h5>
                    <v-chip v-if="!!contract.url" label color="success" small>
                      <span>Enviado</span>
                    </v-chip>
                    <v-chip v-else label color="error" small>
                      <span>Não enviado</span>
                    </v-chip>
                  </div>

                  <v-divider />
                  <h4>Vistorias</h4>
                  <div
                    v-for="immobile in immobileInspection"
                    :key="immobile.id"
                    class="d-flex justify-space-between align-center"
                  >
                    <h5>{{ immobile.Address | formatAddress }}</h5>
                    <v-chip v-if="!immobile.inspection" label color="error" small>
                      <span>Não solicitada</span>
                    </v-chip>
                    <v-chip v-else-if="!immobile.inspection.url" label color="warning" small>
                      <span>Pendente</span>
                    </v-chip>
                    <v-chip v-else label color="success" small>
                      <span>Enviada</span>
                    </v-chip>
                  </div>
                </div>
              </v-card>
              <v-card v-if="isValidToSign" outlined class="pa-4 mt-2">
                <v-form v-model="witnessValid" @submit.prevent>
                  <h4>Testemunhas</h4>
                  <v-text-field
                    v-for="(witness, i) in witnesses"
                    :key="i"
                    :id="`witness-${i}`"
                    class="mt-3"
                    v-model="witnesses[i]"
                    :label="`E-mail | Testemunha ${i + 1}`"
                    type="email"
                    :rules="[v => !!v || 'Campo obrigatório', v => /.+@.+\..+/.test(v) || 'E-mail inválido']"
                    dense
                    outlined
                    hide-details
                    :append-outer-icon="i > 1 ? 'mdi-minus' : undefined"
                    @click:append-outer="removeWitness(i)"
                  />
                  <v-btn block color="primary" @click="addWitness" class="mt-3">Adicionar testemunha</v-btn>
                </v-form>
              </v-card>
            </v-stepper-content>

            <v-stepper-content step="2">
              <div class="d-flex flex-column gap-3">
                <h4>Assinaturas</h4>
                <div
                  class="d-flex align-center gap-3"
                  v-for="signature in contract.DocSign?.Signature || []"
                  :key="signature.id"
                >
                  {{ signature.email }}
                  <v-divider />
                  <v-chip label :color="statusTable[signature.status].color" small>
                    <span>{{ statusTable[signature.status].text }}</span>
                  </v-chip>
                </div>
              </div>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-card-text>

      <v-card-actions >
        <v-btn color="primary" outlined :disabled="loading" @click="close">Fechar</v-btn>
        <v-spacer />
        <v-btn
          v-if="step == 1"
          color="primary"
          :loading="loading"
          :disabled="loading || !isValidToSign || !witnessValid"
          @click="sign"
        >
          Enviar para assinatura
        </v-btn>
        <v-btn
          v-else
          text
          :disabled="loading"
          @click="openD4Sign"
        >
          Ver no D4Sign
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import DOCSIGN from '@/services/docsign'

export default {
  data() {
    return {
      loading: false,
      dialog: false,
      rental: false,
      contract: false,
      step: 1,
      witnessValid: false,
      witnesses: ['', ''],
      statusTable: {
        pending: {
          color: 'warning',
          text: 'Pendente',
        },
        signed: {
          color: 'success',
          text: 'Assinado',
        },
        error: {
          color: 'error',
          text: 'Erro ao enviar email',
        },
        canceled: {
          color: 'error',
          text: 'Cancelado',
        },
      },
    }
  },
  methods: {
    async sign() {
      try {
        this.loading = true
        const result = await DOCSIGN.contract.sign({
          contractId: this.contract.id,
          witnesses: this.witnesses,
        })
        this.$toast.fire({
          icon: 'success',
          title: 'Contrato enviado para assinatura',
        })
        this.$emit('success')
        this.contract.DocSign = result.DocSign
        this.step = 2
      } catch (error) {
        console.error(error)
        this.$toast.fire({
          icon: 'error',
          title: error.message || 'Erro ao enviar contrato para assinatura',
        })
      } finally {
        this.loading = false
      }
    },
    openD4Sign() {
      window.open(`https://secure.d4sign.com.br/desk/viewblob/${this.contract.DocSign.reference}`, '_blank')
    },
    addWitness() {
      this.witnesses.push('')
    },
    removeWitness(i) {
      this.witnesses.splice(i, 1)
    },
    open({ rental, contract }) {
      this.dialog = true
      this.rental = structuredClone(rental)
      this.contract = structuredClone(contract)
      this.step = contract.DocSign && contract.DocSign.status !== "canceled" ? 2 : 1
      this.witnesses = ['', '']
    },
    close() {
      this.loading = false
      this.dialog = false
      this.rental = false
      this.contract = false
    },
  },
  computed: {
    isValidToSign() {
      if (!this.rental) return false
      if (!this.contract.url) return false
      if (!this.immobileInspection.every(i => i.inspection && i.inspection.url)) return false
      return true
    },
    immobileInspection() {
      if (!this.rental) return []
      const inspections = this.rental.Inspection.sort((a, b) => {
        return new Date(b.createdAt) - new Date(a.createdAt)
      })
      return this.rental.Immobiles.map(i => {
        return {
          ...i,
          inspection: inspections.find(ins => ins.immobileId === i.id),
        }
      })
    },
  },
  created() {
    this.$root.$on(this.componentName, this.open)
  },
  props: {
    componentName: {
      type: String,
      default: 'contract-sign',
    },
  },
}
</script>

<style></style>

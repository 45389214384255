<template>
  <v-dialog v-model="dialog" max-width="500px">
    <v-card class="pa-4">
      <v-card-title class="justify-center text-center"
        >Tem certeza que deseja <br />encerrar este contrato?</v-card-title
      >
      <v-card-actions class="mt-4">
        <v-spacer></v-spacer>
        <v-btn color="primary" class="mr-2 mt-2" outlined :disabled="loading" @click="close">Fechar</v-btn>
        <v-btn color="primary" class="mt-2" :loading="loading" @click="cancel">Encerrar Contrato</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import RENTAL from '@/services/rental'

export default {
  data: () => ({
    dialog: false,
    loading: false,
    rental: {},
    contract: {},
  }),
  methods: {
    async cancel() {
      try {
        this.loading = true
        await RENTAL.contract.cancel(this.rental.id, this.contract.id)
        this.$emit('success')
        this.close()
      } catch (error) {
        this.loading = false
        this.$toast.fire({
          icon: 'error',
          title: error.message || 'Erro ao cancelar contrato',
        })
      }
    },
    open({ rental, contract }) {
      this.dialog = true
      this.rental = rental
      this.contract = contract
    },
    close() {
      this.loading = false
      this.dialog = false
      this.rental = false
      this.contract = false
    },
  },
  created() {
    this.$parent.$on(this.componentName, this.open)
  },
  props: {
    componentName: {
      type: String,
      default: 'contract-cancel',
    },
  },
}
</script>

<style></style>

<template>
  <v-dialog v-model="dialog" max-width="1024px">
    <v-card>
      <v-card-title>
        <span class="text-h5">
          {{ boleto.payment.description }}
          <v-chip class="ml-2" :color="paymentStatus" outlined label>
            {{ boleto.payment.validity | date }}
          </v-chip>
        </span>
      </v-card-title>
      <v-card-text>
        <iframe :src="boleto.boleto.link"></iframe>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="copyCode"> Copiar </v-btn>
        <v-btn color="blue darken-1" text @click="openNewWindow"> Imprimir </v-btn>
        <v-btn color="blue darken-1" text @click="close"> Fechar </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from "moment";

export default {
  data() {
    return {
      dialog: false,
      boleto: {
        boleto: {
          link: ""
        },
        payment: {
          description: "",
          validity: ""
        }
      }
    };
  },
  methods: {
    copyCode() {
      try {
        navigator.clipboard.writeText(this.boleto.boleto.barcode);
        this.$toast.fire({
          title: "Código copiado",
          icon: "success",
          showCloseButton: true
        });
      } catch {
        this.$toast.fire({
          title: "Erro ao copiar código",
          icon: "error"
        });
      }
    },
    openNewWindow() {
      window.open(this.boleto.boleto.link, "_blank");
    },
    open(boleto) {
      this.boleto = boleto;
      this.dialog = true;
    },
    close() {
      this.dialog = false;
      this.boleto = {
        boleto: {
          link: ""
        },
        payment: {
          description: "",
          validity: ""
        }
      };
    }
  },
  computed: {
    paymentStatus() {
      let payment = this.boleto.payment;
      if (!payment.status) return "warning";
      if (payment.status == "Paid") return "success";
      if (payment.status == "Canceled") return "secondary";
      if (payment.status == "Pending" && moment().isAfter(payment.validity)) "error";
      return "warning";
    }
  },
  filters: {
    date(value) {
      return moment(value).format("DD/MM/YYYY");
    }
  },
  created() {
    this.$root.$on(this.componentName, this.open);
  },
  props: {
    componentName: {
      type: String,
      default: "show-boleto"
    }
  }
};
</script>

<style scoped>
iframe {
  border: none;
  width: 100%;
  height: calc(90vh - 200px);
}
</style>

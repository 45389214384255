<template>
  <v-data-table
    show-expand
    :headers="headers"
    :items="contract.ContractVersion"
    single-expand
    sort-by="createdAt"
    sort-desc
  >
    <template v-slot:item.id="{ item }">
      {{ item.id | idFilter }}
    </template>
    <template v-slot:item.createdAt="{ item }">
      {{ item.createdAt | date('DD/MM/YYYY HH:mm') }}
    </template>
    <template v-slot:item.CreatedBy="{ item }">
      {{ item.CreatedBy?.name || "Sistema" }}
    </template>
    <template v-slot:expanded-item="{ headers, item }">
      <td :colspan="headers.length">
        <contract-version-info :contract-version="item" />
      </td>
    </template>
  </v-data-table>
</template>

<script>
import ContractVersionInfo from './ContractVersionInfo.vue'
export default {
  components: { ContractVersionInfo },
  data: () => ({
    headers: [
      { text: 'Versão', value: 'id' },
      { text: 'Data', value: 'createdAt' },
      { text: 'Usuário', value: 'CreatedBy' },
      { text: '', value: 'data-table-expand' },
    ],
  }),

  filters: {},

  props: {
    contract: {
      type: Object,
      default: () => ({}),
    },
  },
}
</script>

<style></style>

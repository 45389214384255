<template>
  <v-card id="user-profile-card" :loading="loading">
    <!-- tabs -->
    <v-tabs v-model="tab" class="mt-1" show-arrows>
      <v-tab v-for="(tab, index) in loading ? [] : computedTabs" :key="index">
        <v-icon size="20" class="me-3">
          {{ tab.icon }}
        </v-icon>
        <span>{{ tab.title }}</span>
      </v-tab>
    </v-tabs>

    <!-- tabs item -->
    <v-tabs-items v-model="tab">
      <v-tab-item v-for="(tab, index) in computedTabs" :key="`${tab.component}-${index}`">
        <component :is="tab.component" :rental="rental" :loading="deepLoading" @refresh="getRental" />
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import detail from './Detail.vue'
import payment from './Payment.vue'
import inspection from './Inspection.vue'
import contract from './Contract.vue'
import attachment from './Attachment.vue'
import { mapGetters } from 'vuex'
import RENTAL from '@/services/rental'

export default {
  data: () => ({
    loading: true,
    deepLoading: false,
    rental: {},
    tab: null,
    tabs: [
      {
        title: 'Detalhes',
        component: 'detail',
        icon: 'mdi-text-box-check-outline',
        id: '',
      },
      {
        title: 'Pagamento(s)',
        permission: 524288,
        component: 'payment',
        icon: 'mdi-cash-multiple',
        id: 'payment',
      },
      {
        title: 'Vistoria(s)',
        permission: 32768,
        component: 'inspection',
        icon: 'mdi-home-search',
        id: 'inspection',
      },
      {
        title: 'Contrato(s)',
        permission: 32768,
        component: 'contract',
        icon: 'mdi-file-document-multiple-outline',
        id: 'contract',
      },
      {
        title: 'Anexo(s)',
        component: 'attachment',
        icon: 'mdi-paperclip',
        id: 'attachment',
      },
    ],
  }),
  methods: {
    async getRental() {
      try {
        this.deepLoading = true
        let { id } = this.$route.params
        let rental = await RENTAL.getOneById(id)
        this.rental = rental.rental
        this.loading = false
        this.deepLoading = false
        this.selectTab()
      } catch (e) {
        this.$toast
          .fire({
            title: e.message,
            icon: 'error',
          })
          .then(() => {
            this.$router.push('/dashboard')
          })
      }
    },
    selectTab() {
      if (this.$route.hash) {
        let hash = this.$route.hash.replace('#', '')
        let index = this.tabs.findIndex(tab => tab.id == hash)
        if (index) this.tab = index
      }
    },
  },
  async mounted() {
    this.loading = true
    await this.getRental()
  },

  computed: {
    ...mapGetters('auth', ['hasPermission']),
    computedTabs() {
      return this.tabs.filter(tab => {
        if (!tab.permission) return true
        return this.hasPermission(tab.permission)
      })
    },
  },
  watch: {
    tab() {
      if (this.tab == null) return
      let tab = this.tabs[this.tab]
      this.$router.push({ hash: tab.id }).catch(() => {})
    },
  },
  components: {
    detail,
    payment,
    inspection,
    contract,
    attachment,
  },
}
</script>

<template>
  <div>
    <v-card flat class="mt-5">
      <div class="pa-3">
        <v-card-text v-if="rental">
          <v-btn @click="addInspection" color="primary" v-if="hasPermission(268435456)"> Solicitar Vistoria </v-btn>

          <inspection-table :inspections="rental.Inspection" />
        </v-card-text>
      </div>
    </v-card>
    <inspection-create-modal :rental="rental" @success="$emit('refresh')" />
  </div>
</template>

<script>
import InspectionCreateModal from '@/components/modals/InspectionCreateModal.vue'
import { mapGetters } from 'vuex'
import InspectionTable from '@/components/global/InspectionTable.vue'

export default {
  components: { InspectionCreateModal, InspectionTable },
  data: () => ({}),
  methods: {
    addInspection() {
      this.$emit('add-inspection')
    },
  },
  computed: {
    ...mapGetters('auth', ['hasPermission']),
  },
  props: {
    rental: {
      type: Object,
      required: true,
    },
  },
}
</script>


<template>
  <v-card flat class="mt-5">
    <div class="pa-3">
      <v-card-text v-if="rental">
        <v-row>
          <v-col cols="12" class="align-self-center">
            <v-row>
              <v-col class="align-self-center px-3">
                <h1 class="font-weight-regular px-0">ID: {{ rental.id.slice(0, 8).toUpperCase() }}</h1>
              </v-col>
              <v-col class="align-self-center text-right px-3">
                <v-btn color="primary" @click="$router.go(-1)" outlined>Voltar</v-btn>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12" md="5" class="align-self-start">
            <div class="v-card__title px-0 pt-0 pb-0">Responsáveis</div>
            <v-list>
              <template v-for="c in rental.Clients">
                <v-list-item class="px-0" :key="'l-' + c.client.id">
                  <v-list-item-avatar>
                    <v-icon large>{{
                      c.type == 'Tenant' ? 'mdi-account-key-outline' : 'mdi-account-cash-outline'
                    }}</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>{{ c.client.name }}</v-list-item-title>
                    <v-list-item-subtitle class="text-xs">{{
                      c.type == 'Tenant' ? 'Locatário' : 'Fiador'
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-btn icon :href="`tel:+55${c.client?.phone?.replace(/[^0-9\.]+/g, '')}`">
                      <v-icon color="grey lighten-1">mdi-phone-outline</v-icon>
                    </v-btn>
                  </v-list-item-action>
                  <v-list-item-action class="ml-1">
                    <v-btn icon :href="`mailto:${c.client.email}`">
                      <v-icon color="grey lighten-1">mdi-email-outline</v-icon>
                    </v-btn>
                  </v-list-item-action>
                  <v-list-item-action class="ml-1">
                    <v-tooltip left color="primary">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on">
                          <v-icon color="grey lighten-1">mdi-card-account-details-outline</v-icon>
                        </v-btn>
                      </template>
                      <span style="display: block" v-if="c.client.cpf">CPF: {{ c.client.cpf }}</span>
                      <span style="display: block" v-if="c.client.rg">RG: {{ c.client.rg }}</span>
                      <span style="display: block" v-if="c.client.cnpj">CNPJ: {{ c.client.cnpj }}</span>
                    </v-tooltip>
                  </v-list-item-action>
                </v-list-item>
                <v-divider :key="'d-' + c.client.id"></v-divider>
              </template>
            </v-list>
          </v-col>

          <v-col cols="12" md="7">
            <v-card outlined v-for="(immobile, index) in rental.Immobiles" :key="immobile.id" :class="{'mt-2': index > 0}">
              <div class="d-flex flex-column-reverse flex-md-row justify-space-between">
                <div>
                  <v-card-title
                    >{{ immobile.Address.street }}, {{ immobile.Address.number }} -
                    {{ immobile.Address.city }}</v-card-title
                  >
                  <v-card-text>
                    {{ immobile.Address.street }}, {{ immobile.Address.number }} - {{ immobile.Address.city }},
                    {{ immobile.Address.state }} - {{ immobile.Address.zipCode }}
                  </v-card-text>
                  <v-card-actions class="mt-2">
                    <v-btn
                      :href="
                        `https://www.google.com/maps/place/${immobile.Address.street},+${immobile.Address.number},+${immobile.Address.city}+-+${immobile.Address.state},+${immobile.Address.zipCode}`
                      "
                      target="_blank"
                      color="primary"
                    >
                      Ver no Mapa
                    </v-btn>
                  </v-card-actions>
                </div>
                <div class="pa-4">
                  <v-img
                    :src="immobile.Address.mapUrl"
                    :class="$vuetify.breakpoint.mdAndUp ? 'rounded-lg' : 'rounded-0'"
                    height="100%"
                    :max-width="$vuetify.breakpoint.mdAndUp ? '220' : '100%'"
                  ></v-img>
                </div>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </div>
  </v-card>
</template>

<script>
export default {
  computed: {
    status() {
      let rental = this.rental
      if (!rental) return false
      if (rental.payments != 'Adimplente')
        return { color: 'error', text: 'Inadimplente. Regularize agora', icon: 'mdi-alert-circle-outline' }
      if (rental.status == 'finished') return { color: 'secondary', text: 'Encerrada', icon: '' }
      if (rental.status == 'pendente') return { color: 'secondary', text: 'Inativo', icon: '' }
      return { color: 'success', text: 'Ativo. Parabéns', icon: 'mdi-check-circle-outline' }
    },
  },
  props: {
    rental: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/plugins/vuetify/default-preset/preset/mixins.scss';
</style>

<template>
  <div>
    <v-simple-table v-if="cvList.length">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">Campo</th>
            <th class="text-left">Valor Antigo</th>
            <th class="text-left">Valor Novo</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(value, key) in cvList" :key="key">
            <td>{{ value.label }}</td>
            <td>{{ value.filter ? $options.filters[value.filter](value.oldValue) : value.oldValue }}</td>
            <td>{{ value.filter ? $options.filters[value.filter](value.newValue) : value.newValue }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <v-alert v-else text color="info" class="mt-1">
      Não há alterações entre esta versão e a anterior
    </v-alert>
  </div>
</template>

<script>
export default {
  data: () => ({
    fields: {
      iptuInclude: {
        label: 'IPTU Incluso',
        filter: 'boolean',
      },
      value: {
        label: 'Valor',
        filter: 'currency',
      },
      payday: {
        label: 'Dia de Vencimento',
      },
      startDate: {
        label: 'Data de Início',
        filter: 'date',
      },
      endDate: {
        label: 'Data de Término',
        filter: 'date',
      },
    },
  }),

  computed: {
    cvList() {
      const keys = [
        ...new Set([...Object.keys(this.contractVersion.oldData), ...Object.keys(this.contractVersion.newData)]),
      ]

      return keys
        .map(key => {
          if (this.contractVersion.oldData[key] === this.contractVersion.newData[key]) return null
          return {
            ...(this.fields[key] || { label: key }),
            oldValue: this.contractVersion.oldData[key] || null,
            newValue: this.contractVersion.newData[key] || null,
          }
        })
        .filter(x => x !== null)
    },
  },

  filters: {
    boolean(value) {
      return value ? 'Sim' : 'Não'
    },
  },

  props: {
    contractVersion: {
      type: Object,
      default: () => ({}),
    },
  },
}
</script>

<style>
</style>